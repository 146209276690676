<template>
    <div id="send-opinion-page">
        <toolbar-back-button-component :title="$t('send_opinion_page.give_your_opinion')"/>

        <div class="pa-4">
            <p class="font-weight-bold">{{ $t('send_opinion_page.share_your_experience') }}</p>

            <v-textarea v-model="data.opinion" class="white-text-field" filled
                        :label="$t('send_opinion_page.your_opinion')"
            />

            <v-btn :disabled="data.opinion === ''" :loading="loading_edit" block
                   class="btn-secondary-loader" color="primary" large
                   @click="sendOpinion()"
            >
                <span
                    :class="data.opinion !== '' ? 'secondary--text font-weight-bold': 'font-color-light font-weight-bold'"
                >
                    {{ $t('send_opinion_page.send_your_opinion') }}
                </span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import GlobalRepository from "../../../repositories/GlobalRepository";
import ToolbarBackButtonComponent from "../../../components/Global/ToolbarBackButtonComponent";

export default {
    name: 'SendOpinionPage',
    components: {ToolbarBackButtonComponent},
    data() {
        return {
            data: {
                opinion: ''
            },
            loading_edit: false
        }
    },
    methods: {
        sendOpinion() {
            this.loading_edit = true;
            GlobalRepository.sendOpinion(this.data).then(() => {
                this.showSnackbar('success', this.$t('send_opinion_page.message_send'));
                this.data.opinion = '';
            }).catch((error) => {
                this.manageError(error);
            }).finally(() => {
                this.loading_edit = false;
                this.navigateTo('parameter_comment');
            });
        }
    }
}
</script>